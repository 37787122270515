import { useContext, useLayoutEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import {
	HeaderWrapper,
	ContentWrapper,
	MainBackgroundPic,
	NavContentWrapper
} from './Header.styled'
import { useLocation } from 'react-router-dom'
import NavBurger from '../NavBurger/NavBurger'
import plFlag from '../../utils/svgs/pl.svg'
import enFlag from '../../utils/svgs/en.svg'
import CircleButton from '../CircleButton/CircleButton'
import translate from '../../helpers/translate'
import { LanguageContext } from '../../App'

const Header = () => {
	const [mainPicStatus, setMainPicStatus] = useState<string>('full')
	const { language, setLanguage } = useContext(LanguageContext)

	const location = useLocation()

	const isMobile = window.innerWidth <= 450
	const isHomePageActive = location.pathname === '/'

	const onLocaleButtonClick = () => {
		language === 'pl' ? setLanguage('en') : setLanguage('pl')
	}

	useLayoutEffect(() => {
		if (!isHomePageActive) {
			return setMainPicStatus('semi')
		}
		return setMainPicStatus('full')
	}, [isHomePageActive])

	return (
		<HeaderWrapper>
			<MainBackgroundPic className={mainPicStatus} />
			{!isHomePageActive && (
				<>
					{isMobile ? (
						<NavBurger />
					) : (
						<NavContentWrapper>
							<CircleButton onClick={onLocaleButtonClick}>
								<img
									src={language === 'pl' ? enFlag : plFlag}
									alt={language}
									height='20'
								/>
							</CircleButton>
							<Navbar />
						</NavContentWrapper>
					)}
				</>
			)}
			<ContentWrapper className={mainPicStatus}>
				<h1>Piotr Łukaszczyk</h1>
				<h2>{translate(language, 'header.pianist').toUpperCase()}</h2>
			</ContentWrapper>
		</HeaderWrapper>
	)
}

export default Header
