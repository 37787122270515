import styled, { css } from 'styled-components'

export const NavWrapper = styled.ul<{ vertical?: boolean }>`
	display: flex;
	gap: 16px;
	list-style-type: none;
	font-size: 18px;
	letter-spacing: 1px;
	animation: fadeIn 1s forwards;
	padding-inline-start: 0;

	${({ vertical }) =>
		vertical &&
		css`
			display: grid;
			position: absolute;
			left: 0;
			top: 60px;
			font-size: 22px;
			animation: none;
		`}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
`
