import { useNavigate } from 'react-router-dom'

const Home = () => {
	const navigate = useNavigate()

	setTimeout(() => {
		navigate('o-mnie')
	}, 2000)

	return
}

export default Home
