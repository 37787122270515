import PL from '../locale/pl.json'
import EN from '../locale/en.json'

type Translation = {
    [key: string]: string
}

interface Translations {
    [key: string]: Translation
}

export const translations: Translations = {
    'pl': PL,

    'en': EN,
}