import React, { ReactNode } from 'react'
import { StyledCircleButton } from './CircleButton.styled'

type CircleButtonProps = {
	children: ReactNode
	onClick: () => void
}

const CircleButton: React.FC<CircleButtonProps> = ({ children, onClick }) => {
	return <StyledCircleButton onClick={onClick}>{children}</StyledCircleButton>
}

export default CircleButton
