import { useContext } from 'react'
import { LanguageContext } from '../App'
import translate from '../helpers/translate'

const About = () => {
	const { language } = useContext(LanguageContext)

	return (
		<>
			<h1>{translate(language, 'header.about-me')}</h1>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quam leo,
				vestibulum vitae eros sed, sodales vulputate urna. Quisque fringilla,
				felis at ullamcorper pellentesque, elit arcu ultrices ex, vitae tempor
				quam velit nec ante. Phasellus ultrices odio quis tellus bibendum
				ullamcorper. Donec dignissim orci non tellus aliquet, sit amet luctus
				ligula varius. Donec vitae turpis neque. Proin vulputate, diam et
				rhoncus efficitur, leo nulla porttitor nisl, sed fringilla massa leo sit
				amet nisi.
			</p>
			<p>
				Donec tincidunt lectus eu odio congue, a pellentesque nulla tincidunt.
				Cras magna turpis, placerat non congue id, cursus a velit. Maecenas
				consectetur eleifend massa, eu cursus velit. Ut vitae nisi eu dui tempor
				molestie. Aliquam convallis porta pharetra. Praesent mi enim, fringilla
				vel justo et, fermentum cursus justo. Suspendisse at ullamcorper felis.
				Sed vel purus tortor. Aliquam sodales augue non dui convallis, eu
				dapibus eros rutrum. Nunc a pharetra turpis. Donec sollicitudin congue
				ipsum eget porttitor.
			</p>
			<p>
				Aliquam id sem gravida est pharetra tempor. Nam faucibus varius ex, vel
				dictum augue luctus et. Vestibulum luctus commodo pharetra. Suspendisse
				sed nibh sed lectus mollis euismod eu vel neque. Suspendisse cursus
				tincidunt est, ac molestie mi viverra et. Donec ac libero metus. Aenean
				eget turpis et purus imperdiet pharetra et a eros. Vivamus ut ultrices
				quam. Mauris mollis fringilla porta. Pellentesque viverra ex odio, sed
				dapibus nibh molestie ac. In congue metus sit amet semper pretium. Cras
				quis lectus maximus, aliquam quam id, facilisis velit. Quisque sit amet
				nisi dictum, efficitur lectus eget, interdum quam. In tellus lorem,
				finibus quis interdum eu, gravida eget est. Donec faucibus augue ante,
				id varius erat facilisis in. Integer lobortis purus at orci rhoncus, nec
				congue augue tincidunt.
			</p>
			<p>
				Sed interdum, nunc a convallis porta, tellus sapien varius enim, non
				condimentum velit turpis id dolor. Pellentesque sodales malesuada ante,
				nec maximus arcu pharetra non. Etiam dictum purus neque, vestibulum
				lacinia quam elementum eget. Quisque cursus ligula interdum elit rutrum
				interdum. Aliquam erat volutpat. Quisque tincidunt sem auctor ultricies
				convallis. Nunc elementum vel nunc at dapibus. Nam eu pellentesque
				ipsum, id dignissim ipsum. Cras consequat ipsum ut sodales suscipit.
				Aliquam quis tincidunt nulla, bibendum pellentesque odio. Pellentesque
				purus lacus, scelerisque at mollis sit amet, ultricies eget sem. Ut
				pulvinar suscipit eros, et finibus ligula ultricies quis. Duis aliquet
				est in ligula scelerisque placerat. Sed rutrum cursus mauris, placerat
				vulputate neque. Suspendisse tellus elit, maximus vel nulla a,
				sollicitudin dignissim eros. Maecenas lorem sapien, scelerisque ut
				fermentum vitae, tincidunt a libero. Praesent commodo molestie justo,
				vel lobortis odio faucibus eu. Donec convallis porttitor augue, vel
				aliquam urna. Aliquam ac nisl erat.
			</p>
		</>
	)
}

export default About
