import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from './components/Header/Header'
import Home from './pages/Home'
import About from './pages/About'

export const LanguageContext = React.createContext()

function App () {
    const [language, setLanguage] = useState('pl')
    return (
        <div className='App'>
            <LanguageContext.Provider value={{ language, setLanguage }}>
                <Header />
                <div>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='o-mnie' element={<About />} />
                        <Route path='repertuar' element={<h1>Repertuar</h1>} />
                        <Route path='galeria' element={<h1>Galeria</h1>} />
                        <Route path='kontakt' element={<h1>Kontakt</h1>} />
                    </Routes>
                </div>
            </LanguageContext.Provider>
        </div>
    )
}

export default App
