import styled, { css } from 'styled-components'
import { devices } from '../../utils/media'
import { StyledCircleButton } from '../CircleButton/CircleButton.styled'
import { NavWrapper } from '../Navbar/Navbar.styled'

export const BurgerWrapper = styled.div<{ burgerStatus: string }>`
	width: 100%;
	text-align: right;
	top: 0;
	left: 0;
	z-index: 10;
	padding-top: 0.75rem;
	padding-bottom: 1rem;

	& button {
		background-color: transparent;
		padding: 0;
		width: 3rem;
		border: none;
		margin-right: 1rem;

		&:focus {
			outline: none;
		}
	}

	${({ burgerStatus }) =>
		burgerStatus &&
		css`
			position: ${burgerStatus === 'open' ? 'fixed' : 'absolute'};
		`}

	@media ${devices.tablet} {
		display: none;
	}
`

export const IconWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;

	& i {
		background-color: white;
		width: 28px;
		height: 3px;
		margin: 4px;
		border: none;
		transition: all ease 0.4s;
	}

	.open:nth-child(1) {
		transform: rotate(45deg) translateY(15px);
		margin-left: 25px;
		opacity: 0.85;
	}

	.open:nth-child(2) {
		opacity: 0;
	}

	.open:nth-child(3) {
		transform: rotate(-45deg) translateY(-15px);
		margin-left: 25px;
		opacity: 0.85;
	}

	.close:nth-child(1) {
		transform: rotate(0) translateY(0);
		opacity: 0.75;
	}

	.close:nth-child(2) {
		opacity: 0.75;
	}

	.close:nth-child(3) {
		transform: rotate(0) translateY(0);
		opacity: 0.75;
	}
`

export const MobileContentWrapper = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 1) 70%,
		rgba(0, 0, 0, 0.9) 80%,
		rgba(0, 0, 0, 0.7) 90%,
		rgba(0, 0, 0, 0) 100%
	);

	display: grid;
	grid-template-columns: 2rem auto;
	grid-template-rows: 1.5fr 1fr;
	grid-template-areas:
		'. nav'
		'. btn';

	${StyledCircleButton} {
		grid-area: btn;
	}

	${NavWrapper} {
		grid-area: nav;
	}

	&.empty {
		display: none;
	}

	&.open {
		animation: slideIn 0.75s forwards;
	}

	@keyframes slideIn {
		from {
			top: -130vh;
		}

		to {
			top: 0;
		}
	}
`
