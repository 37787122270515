import styled from 'styled-components'
import { devices } from '../../utils/media'
import { StyledCircleButton } from '../CircleButton/CircleButton.styled'
import { NavWrapper } from '../Navbar/Navbar.styled'

export const HeaderWrapper = styled.header`
	width: 100%;
	position: relative;
`

export const NavContentWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	height: 3rem;
	background-color: black;

	${StyledCircleButton} {
		margin-left: 1rem;
	}

	${NavWrapper} {
		margin-right: 1rem;
	}
`

export const ContentWrapper = styled.div`
	display: grid;
	text-align: center;
	position: absolute;
	left: 50%;
	top: 30%;
	transform: translate(-50%, -50%);
	color: white;

	&.semi {
		top: 45%;
	}

	h1 {
		font-size: 34px;
		letter-spacing: 4px;
		margin-left: 4px;
		margin-bottom: 0.5rem;
	}

	h2 {
		font-size: 14px;
		margin: 0;
		letter-spacing: 15px;
		margin-left: 15px;
	}

	@media ${devices.mobileHorizontal} {
		top: 40%;

		h1 {
			font-size: 40px;
			letter-spacing: 22px;
			margin-left: 22px;
			margin-bottom: 0.75rem;
		}

		h2 {
			font-size: 16px;
			letter-spacing: 10px;
			margin-left: 10px;
		}
	}

	@media ${devices.tablet} {
		top: 45%;

		h1 {
			font-size: 44px;
		}

		h2 {
			font-size: 16px;
		}
	}

	@media ${devices.laptop} {
		h1 {
			font-size: 55px;
		}

		h2 {
			font-size: 20px;
		}
	}
`

export const MainBackgroundPic = styled.div`
	background-image: url('https://images.unsplash.com/photo-1512733596533-7b00ccf8ebaf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 25%;
	width: 100vw;

	&.full {
		height: 100vh;
		transition: height 0.75s ease;
	}

	&.semi {
		height: 35vh;
		transition: height 0.75s ease;
	}

	@media ${devices.mobileL} {
		&.semi {
			height: 22rem;
		}
	}
`
