import styled from 'styled-components'

export const StyledCircleButton = styled.div`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
	& img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-radius: 50%;
	}
`
