import React, { useContext } from 'react'
import { LanguageContext } from '../../App'
import translate from '../../helpers/translate'
import { Navlink } from '../Navlink/Navling'
import { NavWrapper } from './Navbar.styled'

type NavbarProps = {
	vertical?: boolean
	onClose?: () => void
}

const Navbar: React.FC<NavbarProps> = ({ vertical, onClose }) => {
	const { language } = useContext(LanguageContext)

	const navOptions = [
		{
			path: '/o-mnie',
			name: translate(language, 'header.about-me').toUpperCase()
		},
		{
			path: '/repertuar',
			name: translate(language, 'header.repertoire').toUpperCase()
		},
		{
			path: '/galeria',
			name: translate(language, 'header.gallery').toUpperCase()
		},
		{
			path: '/kontakt',
			name: translate(language, 'header.contact').toUpperCase()
		}
	]
	return (
		<NavWrapper vertical={vertical}>
			{navOptions.map(({ path, name }) => {
				return (
					<li key={name} onClick={onClose}>
						<Navlink to={path}>{name}</Navlink>
					</li>
				)
			})}
		</NavWrapper>
	)
}

export default Navbar
