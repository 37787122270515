import React, { useState, useContext } from 'react'
import { LanguageContext } from '../../App'
import CircleButton from '../CircleButton/CircleButton'
import Navbar from '../Navbar/Navbar'
import {
	BurgerWrapper,
	IconWrapper,
	MobileContentWrapper
} from './NavBurger.styled'
import plFlag from '../../utils/svgs/pl.svg'
import enFlag from '../../utils/svgs/en.svg'

const NavBurger = () => {
	const [burgerStatus, setBurgerStatus] = useState<string>('empty')
	const { language, setLanguage } = useContext(LanguageContext)

	const toggleBurgerStatus = () => {
		setBurgerStatus(burgerStatus === 'open' ? 'close' : 'open')
	}

	const onLocaleButtonClick = () => {
		language === 'pl' ? setLanguage('en') : setLanguage('pl')
	}

	const onClose = () => {
		setBurgerStatus('close')
	}

	return (
		<>
			<BurgerWrapper burgerStatus={burgerStatus}>
				<button onClick={toggleBurgerStatus}>
					<IconWrapper onClick={toggleBurgerStatus}>
						<i className={burgerStatus} />
						<i className={burgerStatus} />
						<i className={burgerStatus} />
					</IconWrapper>
				</button>
			</BurgerWrapper>
			{burgerStatus === 'open' && (
				<MobileContentWrapper className={burgerStatus}>
					<Navbar vertical onClose={onClose} />
					<CircleButton onClick={onLocaleButtonClick}>
						<img
							src={language === 'pl' ? enFlag : plFlag}
							alt={language}
							height='20'
						/>
					</CircleButton>
				</MobileContentWrapper>
			)}
		</>
	)
}

export default NavBurger
