const sizes = { 
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobileHorizontal:'650px',
    tablet: '768px',
    tabletHorizontal: '900px',
    laptop: '1024px',
    desktop: '1400px',
    desktopL: '1901px'
}

export const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`, 
    mobileHorizontal: `(min-width: ${sizes.mobileHorizontal})`,
    tablet: `(min-width: ${sizes.tablet})`,
    tabletHorizontal: `(min-width: ${sizes.tabletHorizontal})`,
    laptop: `(min-width: ${sizes.laptop})`,
    desktop: `(min-width: ${sizes.desktop})`,
    desktopL: `(min-width: ${sizes.desktopL})`
}